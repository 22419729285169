import React from "react";
import CTAButton from "src/components/ctabutton";
import { subscribeToConnect } from "src/modules/newsletter/newsletter.service";
import translations from "src/modules/newsletter/newsletter.translations";
import { ApplicationContext } from "src/context";
import Spinner from "src/components/spinner";

type NewsletterComponentProps = {
    language: string;
    textAlign: "left" | "center";
    className?: string;
    title: string | undefined;
    body: React.ReactNode;
    placeholder: string | undefined;
    button_text: string | undefined;
    cta_button_config: {
        button_type: "primary" | "secondary" | "soft";
        use_default_layout_colors: boolean;
        label_color: TColor;
        hover_label_color: TColor;
        background_color: TColor;
        hover_background_color: TColor;
        outline_color: TColor;
        hover_outline_color: TColor;
    };
    email_field_focus_color: TColor;
    integration_setting: string | null;
}

export default class NewsletterComponent extends React.Component<NewsletterComponentProps, NewsletterState> {
    /** @ts-ignore */
    context!: React.ContextType<typeof ApplicationContext>;
    static contextType = ApplicationContext;

    state: NewsletterState = {
        subscribingToMail: false,
        email: "",
        feedback: "none",
    }

    static defaultProps: Partial<NewsletterComponentProps> = {
        textAlign: 'center',
        className: '',
    }

    public get textAlignClass() {
        if (this.props.textAlign === 'left') return 'text-left';
        return 'text-center';
    }

    public get elementsAlignClass() {
        if (this.props.textAlign === 'left') return 'items-start';
        return 'items-center';
    }

    public get emailFocusColor() {
        if (this.props.email_field_focus_color?.value) return "focus:ring-[color:var(--email-focus-color)] border-0"
        return '';
    }

    public get style(): React.CSSProperties & any {
        return {
            '--newsletter-email-background-color': '#FFF',
            '--newsletter-email-foreground-color': '#222',
            "--email-focus-color": this.props.email_field_focus_color?.value
        }
    }

    /** store form reference for validation */
    public formRef = React.createRef<HTMLFormElement>();

    /** on form submit: browser validation passed ok */
    formSubmit: React.FormEventHandler<HTMLFormElement> = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.subscribeToNewsletter(this.state.email);
    }

    /** onClick: request form submit and validation */
    onCTAclick = (ev: any) => {
        ev.preventDefault();
        this.formRef.current?.requestSubmit();
    }

    subscribeToNewsletter = (email: string) => {
        this.setState({ subscribingToMail: true, feedback: "none" }, async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const response = await subscribeToConnect(email, this.props.language, this.props.integration_setting);
          let feedback: NewsletterFeedback = "error";
          if (response.status === "ok") { feedback = "subscribed"; }
          if (response.status !== "ok" && response.message === "BAD_EMAIL") { feedback = "bad.email.format"; }
          if (response.status !== "ok" && response.message === "EXISTING_EMAIL") { feedback = "already.subscribed"; }

          this.setState({
            subscribingToMail: false,
            email: feedback === "subscribed" ? "" : this.state.email,
            feedback: feedback,
          })
        })
    }

    renderFeedback() {
        if (this.state.feedback === "none") return <div className={`-mt-2 generalText`}>&nbsp;</div>
        const color = this.state.feedback === "subscribed" ? "text-lime-600" : "text-red-600";
        const text = this.context.translate(translations, this.state.feedback) as string;
        return <div className={`-mt-2 ${color} ${this.textAlignClass} max-w-[410px] generalText`}>{text}</div>
    }

    renderCTAButton() {
        const cfg = this.props.cta_button_config || {};
        return <>
        <CTAButton
            loading={this.state.subscribingToMail}
            type={cfg.button_type}
            onClick={(e) => this.onCTAclick(e)}
            className="w-full md:w-auto"
            useDiv={true}
            icon={this.state.subscribingToMail ? <div className="ml-3"><Spinner /></div> : null}
            text={this.props.button_text}
            color={cfg.use_default_layout_colors ? undefined : cfg.label_color?.value}
            border={cfg.use_default_layout_colors ? undefined : cfg.outline_color?.value}
            bgcolor={cfg.use_default_layout_colors ? undefined : cfg.background_color?.value}
            hovercolor={cfg.use_default_layout_colors ? undefined : cfg.hover_label_color?.value}
            hoverborder={cfg.use_default_layout_colors ? undefined : cfg.hover_outline_color?.value}
            hoverbgcolor={cfg.use_default_layout_colors ? undefined : cfg.hover_background_color?.value}
            />
        </>
    }

    render() {
        return <>
        <form name="newsletter" className={this.props.className} style={this.style} ref={this.formRef} onSubmit={this.formSubmit}>
            <div className={`flex-col justify-start ${this.elementsAlignClass} gap-4 inline-flex`}>
                <div className="self-stretch flex-col justify-start items-center gap-2 flex">
                    <div className={`self-stretch ${this.textAlignClass} text-[color:var(--titles-color)] footerTitle leading-normal`}>{this.props.title}</div>
                    <div className={`self-stretch ${this.textAlignClass} text-[color:var(--text-color)] footerText leading-snug`}>{this.props.body}</div>
                </div>
                <div className={`w-full max-w-[410px] justify-start items-center gap-3 inline-flex flex-col md:flex-row`}>
                    <input
                        name="newsletter"
                        type="email"
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                        className={`rounded-md border border-white grow shrink basis-0 w-full md:w-auto placeholder-opacity-25 placeholder-[color:var(--newsletter-email-foreground-color)] bg-[color:var(--newsletter-email-background-color)] text-[color:var(--newsletter-email-foreground-color)] ${this.emailFocusColor}`}
                        placeholder={this.props.placeholder}
                    />
                    {this.renderCTAButton()}
                </div>
                {this.renderFeedback()}
            </div>
        </form>
        </>
    }
}