const pages = require("./../../../src/../src-dynamic/destination.pages");
const { getSlug } = require("./../../@modules/utils.slugs");
const { translate } = require("./../../@modules/utils.language");

/**
 * Get CTA Button label
 *
 * @param {TCTAButton} call_to_action
 * @param {string} language
 * @param {string} defaultLanguage
 * @returns
 */
module.exports.getLabelForCTAButton = function getLabelForCTAButton(call_to_action, language, defaultLanguage) {
    let buttonText = undefined;

    if (call_to_action.cta_link_type === 'internal') {
        buttonText = translate(call_to_action.translations, language, 'cta_button_label');
    } else if (call_to_action.cta_link_type === 'entry') {
        buttonText = translate(call_to_action.translations, language, 'cta_button_label');
    } else if (call_to_action.cta_link_type === 'action') {
        buttonText = translate(call_to_action.translations, language, 'cta_button_label');
    } else if (call_to_action.cta_link_type === 'external') {
        buttonText = translate(call_to_action.link_translations, language, 'cta_button_label');
    }

    return buttonText;
}

/**
 * Returns CTA Button link
 * @param {TCTAButton} call_to_action
 * @param {string} language
 * @param {string} defaultLanguage
 * @returns
 */
module.exports.getLinkForCTAButton = function getLinkForCTAButton(call_to_action, language, defaultLanguage) {
    const notFoundPage = pages.not_found_page;

    let buttonLink = undefined;

    if (call_to_action.cta_link_type === 'internal') {
        const removeFinalSlash = new RegExp(/\/+$/g);
        buttonLink = getSlug(language, defaultLanguage, call_to_action.cta_button_linked_page?.translations || []).replace(removeFinalSlash, '/');
        const removeSlashesAndLanguage = new RegExp(`^\\/(${language})\\/|\\/\\/+`, 'g');
        const noSlug = buttonLink.replace(removeSlashesAndLanguage, '') === "";
        if (noSlug && notFoundPage) {
            buttonLink = translate(notFoundPage, language, "slug");
        }
    } else if (call_to_action.cta_link_type === 'entry') {
        buttonLink = getSlug(language, defaultLanguage, call_to_action.cta_button_linked_entry?.translations || []);
    } else if (call_to_action.cta_link_type === 'external') {
        buttonLink = translate(call_to_action.link_translations, language, 'cta_button_link');
    }

    return buttonLink;
}