import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession, setSession, setCustomerToken } from 'src/utils/localStorage';

export function getCustomer () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.hasOwnProperty('PaymentType')) { // PaymentType could be null, it means that the customer is not logged in
        setSession({ ...session, customer: data });
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getCustomer', err);
      reject(err)
    });
  })
};

export function customerLogin ({ email, password }) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer/login`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        email,
        password
      }
    })
    .then((response) => {
      const data = response.data || null;
      setCustomerToken(data.Token);
      if (data.FirstName) {
        setSession({ ...session, customer: data });
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/customerLogin', err);
      reject(err)
    });
  })
};

export function createCustomer ({ EmailAddress, FirstName, LastName, Password }) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer/addAccount`,
      method: 'POST',
      data: {
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        emailAddress: EmailAddress,
        firstName: FirstName,
        lastName: LastName,
        password: Password
      }
    })
    .then((response) => {
      const data = response.data || null;
      setCustomerToken(data.Token);
      if (data.CustomerId) {
        resolve(data);
      }

      reject(data);
    })
    .catch(err => {
      console.log('error service/createCustomer', err);
      reject(err)
    });
  })
};

export function updateCustomer (customer) {
  return new Promise((resolve, reject) => {
    const URL = 'https://zl8glpr7uf.execute-api.us-east-2.amazonaws.com/PROD';

    const inntopiaSalesId = getSalesId();
    const session = getSession();

    // remove unnecessary (and not editable) fields coming from getCustomer
    ['ShippingFullAddress', 'HasAccount', 'UserName', 'IsToken', 'Gender', 'BirthDate', 'PaymentType', 'CvvNotAcceptedMessage', 'Password']
    .forEach(f => {
      if (f in customer) {
        delete customer[f];
      }
    });

    request({
      url: `${URL}/updatecustomer`,
      method: 'POST',
      data: {
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        ...customer
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data[0].FirstName) {
        setSession({ ...session, customer: data[0] });
        resolve(data[0]);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/updateCustomer', err);
      reject(err)
    });
  })
};

export function uploadImage (file) {
  let fd = new FormData();
  fd.append('image', file, file.fileName);

  return new Promise ((resolve, reject) => {
    request({
      url: CONNECT + '/inntopia/imageUpload',
      method: 'POST',
      headers: {
          'accept': 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
      }, data: fd
    })
    .then(res => {
      resolve(res)
    })
  })
}

export function addAnonymous (customerData) {
  const session = getSession();

  return new Promise((resolve, reject) => {
    request({
        url: `${CONNECT}/inntopia/customer/addAnonymous`,
        method: 'POST',
        data: {
            sessionId: session?.uuid,
            salesId: INNTOPIA_SALES_ID,
            firstName: customerData.FirstName,
            lastName: customerData.LastName,
            emailAddress: customerData.EmailAddress
        }
    })
    .then( (data) => {
        if(data.status === 200){
            if(data.data.Token){
              setCustomerToken(data.data.Token);
            }
            return resolve(data.data);
        }else{
            return reject(false);
        }
    });
  });
}