import React from "react";

import AppStores from "./components/appstore";
import CopyText from "./components/copytext";
import SocialMedia from "./components/socialmedia";
import SupportingText from "./components/supportingtext";
import NewsletterComponent from "./components/newsletter";
import transformData_logosGrid from "src/@modules/logosGrid/logosGrid.transform";
import LogosGridFooter from 'src/@modules/logosGrid/logosGrid.footer';

import { CTAButtonV2 } from "src/components/ctabutton";
import Link from "src/components/link";
import ImageV2 from "src/components/image/image.v2";
import ErrorBoundary from "src/components/errorboundary/errorboundary.v1";


import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";

export default class FooterCommonController<T> extends React.Component<{
    language: string;
    defaultLanguage: string;
    footer: T & TFooter;
}> {

    /** Specifies css vars */
    public get style(): TFooterCSSVars {
        return {
            '--background-color': this.props.footer.background_color?.value,
            '--titles-color':  this.props.footer.titles_color?.value,
            '--text-color': this.props.footer.text_color?.value,
            '--hyperlink-text-color': this.props.footer.hyperlink_text_color?.value,
            '--hyperlink-hover-color': this.props.footer.hyperlink_hover_color?.value,
            '--link-titles-color': this.props.footer.link_titles_color?.value,
            '--link-labels-color': this.props.footer.link_labels_color?.value,
            '--separator-one-color': this.props.footer.separator_color?.value,
            '--separator-two-color': this.props.footer.separator_two_color?.value,
            '--social-media-icons-color': this.props.footer.social_media_icons_color?.value,
            '--app-store-badges-background-color': this.props.footer.app_stores_badges_background_color?.value,
            '--app-store-badges-text-color': this.props.footer.app_stores_badges_text_color?.value,
            '--logo-slider-background-color': this.props.footer.logo_slider_background_color?.value,
            '--legal-background-color': this.props.footer.legal_background_color?.value,
        }
    }

    /** get main background styled image */
    get mainBackgroundStyle(): any {
        if (!this.props.footer.main_background_image?.imageFile) return null;
        const background_image = this.props.footer.main_background_image?.imageFile;
        const image_uri = ImageV2.getImageUrl(background_image);
        return {
            backgroundImage: `url(${image_uri})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };
    }

    /** Render column with title */
    public renderColumn(titleTranslations: Translations<any>, titleKey: string, linkItems: TFooterLinkItem[]) {
        const title = getLanguageField(titleTranslations, this.props.language, titleKey);
        const options = (linkItems || []).map(({footer_link_item_id}, index) => {
            const label = getLanguageField(footer_link_item_id.translations, this.props.language, 'label', false) as string;
            const href = footer_link_item_id.link_type === 'external'
                ? getLanguageField(footer_link_item_id.link_translations, this.props.language, 'link_href') as string
                : getSlug(this.props.language, this.props.defaultLanguage, footer_link_item_id.link_page?.translations);
            return <Link key={'footer_linkleft_' + index} className="footerText" title={label} href={href}>{label}</Link>
        });
        return <div className="flex grow flex-col gap-4">
            {title ? <div className="footerTitle text-[color:var(--link-titles-color)]">{title}</div> : null}
            {options}
        </div>
    }

    /** renders logo */
    public renderLogo(): React.ReactNode {
        if (!this.props.footer.logo) return null;
        const logoUrl = getLanguageField(this.props.footer.translations, this.props.language, 'logo_url') as string;
        const opensInSameTab = getLanguageField(this.props.footer.translations, this.props.language, 'open_in_same_tab');
        const logoSrc = ImageV2.getImageUrl(this.props.footer.logo?.imageFile, false);
        if (!logoUrl)
            return <ImageV2
                className=""
                alt="Logo"
                src={logoSrc} />
        return <Link className="" title={undefined} href={logoUrl} target={opensInSameTab ? '_self' : '_blank'}><ImageV2
            className=""
            alt="Logo"
            src={logoSrc} />
            </Link>;
    }

    /** renders supporting text */
    public renderSupportingText(centered: boolean = false) {
        const align = centered ? 'text-center' : '';
        const tweakFn = (str: string) => str.replaceAll('generalText2', 'footerText').replaceAll('mb-4', '');
        const text = getLanguageField(this.props.footer.translations, this.props.language, 'supporting_text', true, true, 'footerText text-[color:var(--text-color)] ' + align, tweakFn);
        return <SupportingText>{text}</SupportingText>;
    }

    /** returns google play url if defined */
    public getGooglePlayUrl() {
        return getLanguageField(this.props.footer.translations, this.props.language, 'google_play_app_url', false) as string;
    }

    /** returns apple store url if defined */
    public getAppleStoreUrl() {
        return getLanguageField(this.props.footer.translations, this.props.language, 'apple_store_app_url', false) as string;
    }

    /** returns if has any stores */
    public hasStores() {
        const url1 = this.getGooglePlayUrl();
        const url2 = this.getAppleStoreUrl();
        if (url1 || url2) return true;
        return false;
    }

    /** renders app stores component */
    public renderAppStores(textAlign?: "left" | "center", badgesAlign?: "left" | "center", className?: string, uid?: string) {
        return <AppStores
            className={className}
            uid={uid}
            textAlign={textAlign}
            badgesAlign={badgesAlign}
            googlePlayUrl={this.getGooglePlayUrl()}
            appleStoreUrl={this.getAppleStoreUrl()}
            />
    }

    /** renders social media icons */
    public renderSocialMedia(className?: string) {
        return <SocialMedia className={className} items={this.props.footer.social_media_items} />
    }

    /** returns true if cta is configured */
    public hasCTA() {
        if (!this.props.footer.call_to_action) return false;
        return true;
    }

    /** renders call to action section */
    public renderCTA(alignment: "center" | "left" = "center") {
        if (!this.hasCTA()) return null;

        const textAlign = alignment === 'center' ? 'text-center' : 'text-left';
        const alignClass = alignment === 'center' ? 'justify-center' : 'justify-start';
        const cta_section_title = getLanguageField(this.props.footer.translations, this.props.language, 'call_to_action_section_title') as string;
        const hasSectionTitle = cta_section_title && cta_section_title !== "";
        return <div className={`w-full flex-col justify-start items-center gap-4 inline-flex`}>
                {hasSectionTitle ? <div className={`self-stretch ${textAlign} text-[color:var(--titles-color)] footerTitle leading-normal`}>{cta_section_title}</div> : null}
                <div className={`w-full ${alignClass} flex flex-row`}>
                    <CTAButtonV2
                        language={this.props.language}
                        defaultLanguage={this.props.defaultLanguage}
                        ctabutton={this.props.footer.call_to_action as TCTAButton} />
                </div>
        </div>;
    }

    /** render logos grid */
    public renderLogosGrid() {
        if (!this.props.footer.logos) return null;
        const props = transformData_logosGrid(this.props.footer.logos as any, this.props.language);
        return <ErrorBoundary fallback={null}>
            <div className="w-full flex-col justify-start items-center gap-4 inline-flex">
                <div className="w-full h-px bg-[color:var(--separator-one-color)]" />
            </div>
            <LogosGridFooter {...props} style={this.style as React.CSSProperties}/>
        </ErrorBoundary>
    }

    /** renders legal logo */
    renderLegalLogo() {
        if (!this.props.footer.legal_logo?.imageFile) return null;
        return <ImageV2
            className="max-h-[70px]"
            src={this.props.footer.legal_logo?.imageFile} />
    }

    /** renders legal link items */
    renderLegalLinkItems() {
        return (this.props.footer.footer_legal_link_items || []).map(({footer_link_item_id}, index) => {
            const label = getLanguageField(footer_link_item_id.translations, this.props.language, 'label', false) as string;
            const href = footer_link_item_id.link_type === 'external'
                ? getLanguageField(footer_link_item_id.link_translations, this.props.language, 'link_href') as string
                : getSlug(this.props.language, this.props.defaultLanguage, footer_link_item_id.link_page.translations);
            return <Link key={'footer_link_' + index} className="footerText" title={label} href={href}>{label}</Link>
        })
    }

    get hasLegalLinks() {
        if ((this.props.footer.footer_legal_link_items || []).length === 0) return false;
        return true;
    }

    /** renders legal links row */
    renderLegalLinks() {
        if (!this.hasLegalLinks) return null;
        return <div className="flex flex-row items-center flex-wrap justify-center gap-8 text-[color:var(--text-color)]">
            {this.renderLegalLinkItems()}
        </div>
    }

    /** renders bottom text */
    public renderBottomText() {
        const text = getLanguageField(this.props.footer.translations, this.props.language, 'bottom_text_block', true, true, 'generalText');
        const hasLegalLogo = this.props.footer.legal_logo?.imageFile;
        if (!text && !this.hasLegalLinks && !hasLegalLogo) return null;
        return <>
        <div className="w-full h-px bg-[color:var(--separator-two-color)]" />
        <div className="footer-copytext px-4 pt-8 pb-11 gap-1 w-full flex-col justify-start items-center inline-flex">
            <ErrorBoundary fallback={null}>{this.renderLegalLogo()}</ErrorBoundary>
            <ErrorBoundary fallback={null}>{this.renderLegalLinks()}</ErrorBoundary>
            {text ? <div className="max-w-4xl justify-start items-center inline-flex">
                <CopyText>{text}</CopyText>
            </div> : null}
        </div>
        </>;
    }

    /** renders newsletter */
    public renderNewsletter(textAlign?: "left" | "center", className?: string) {
        const footer = this.props.footer;

        if (footer.with_newsletter_subscription !== true) return null;

        const title = getLanguageField(footer.newsletter_translations, this.props.language, 'newsletter_subscription_title') as string;
        const body = getLanguageField(footer.newsletter_translations, this.props.language, 'newsletter_subscription_body', true, true, 'footer-copytext') as React.ReactNode;
        const placeholder = getLanguageField(footer.newsletter_translations, this.props.language, 'newsletter_input_placeholder') as string;
        const button_text = getLanguageField(footer.newsletter_translations, this.props.language, 'newsletter_button_text') as string;

        const cta_button_config = {
            button_type: footer.newsletter_cta_button_type,
            use_default_layout_colors: footer.newsletter_button_use_default_layout_colors,
            label_color: footer.newsletter_cta_button_label_color,
            hover_label_color: footer.newsletter_cta_button_hover_label_color,
            background_color: footer.newsletter_cta_button_background_color,
            hover_background_color: footer.newsletter_cta_button_hover_background_color,
            outline_color: footer.newsletter_cta_button_outline_color,
            hover_outline_color: footer.newsletter_cta_button_hover_outline_color,
        }

        return <NewsletterComponent
            className={className}
            textAlign={textAlign}
            language={this.props.language}
            title={title}
            body={body}
            placeholder={placeholder}
            button_text={button_text}
            integration_setting={this.props.footer.external_integration?.id}
            cta_button_config={cta_button_config}
            email_field_focus_color={cta_button_config.background_color}

        />;
    }

}