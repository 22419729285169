import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getCustomerToken, getSession } from 'src/utils/localStorage';
import { gtmFunction } from 'src/utils/gtm';

export function checkWaivers() {
  return new Promise((resolve, reject) => {
      request({
          url: `${CONNECT}/inntopia/waiver`,
          method: 'POST',
          data: {
            token: getCustomerToken()
          }
      })
      .catch(err => {
        reject(err);
      })
      .then((response) => {
          if(response.status === 200 && !response.data.error){
              return resolve(response.data)
          }else{
              return resolve(false);
          }
      });
  });
}

export function acceptWaivers(itemJSON) {
  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/inntopia/waiver/accept`,
      method: "POST",
      data: {
        acceptedWaivers: itemJSON,
        salesId: getSession().sales_id,
        itineraryId: getSession().cart.itineraryid,
      }
    })
    .then(() => {
      gtmFunction("fill_waiver", itemJSON);
      resolve(true);
    });
  })
  
}