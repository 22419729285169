module.exports = {
  "defaultCurrency": "CAD",
  "currencies": [
    {
      "id": 3,
      "code": "CAD",
      "name": "CAD",
      "change_store": true,
      "store": "808931",
      "base_currency": "CAD"
    },
    {
      "id": 2,
      "code": "USD",
      "name": "$USD",
      "change_store": true,
      "store": "11415332",
      "base_currency": "USD"
    },
    {
      "id": 1,
      "code": "EUR",
      "name": "€EUR",
      "change_store": true,
      "store": "808931",
      "base_currency": "CAD"
    },
    {
      "id": 4,
      "code": "AUD",
      "name": "$AUD",
      "change_store": true,
      "store": "808931",
      "base_currency": "CAD"
    },
    {
      "id": 5,
      "code": "GBP",
      "name": "£GBP",
      "change_store": true,
      "store": "808931",
      "base_currency": "CAD"
    }
  ]
}